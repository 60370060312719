<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import Permissionform from "./components/Permissionform.vue";

import { fetchAllPermissionsTable } from "@/api/common";
import Swal from "sweetalert2";
import RolesTable from './components/rolesTable.vue';
import {required,decimal,maxLength,requiredIf} from "vuelidate/lib/validators";
export default {
  import: {fetchAllPermissionsTable},
  page: {
    
    title: "Contrats",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, RolesTable },
  // Permissionform
  data() {
    return {
      selectedPermission: null,
      tableData: [],
      List_Permission: [],
      rows:'',
      active:true,
      selectedRows:[],
      permission:undefined,
      title: "Rôles",
      items: [
        {
          text: "Paramètres",
          to: {name: 'base.settings.index'},
        },
        {
          text: "Rôles",
          active: false,
        },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      rolForm:{
        'uuid' : "",
        'name' : "",
      },
      disabled : true,
      canDelte : false,
      disabledAdd : false,
      disabledEdit : true,
      disabledUpdate : false,
      disabledCancel : true,
      submitted: false,
      reloadTable: false,
    }
  },
    validations: {
    rolForm: {
      name : {required}
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;
  },
   watch:{
   
      selectedRows(){
        console.log(this.selectedRows);
      },
  },
  methods:{
     addBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledEdit    = true;
      this.disabledUpdate = false;
      this.clearForms();
      this.canDelte       = true;
      this.disabledCancel = false;
    },
    duplicateBtn(){
      this.disabled         = false;
      this.disabledAdd    = true;
      this.disabledEdit    = true;
      this.disabledUpdate   = false;
      this.canDelte         = true;
      this.rolForm.uuid = "";
      this.disabledCancel   = false;
    },
    editBtn(){
      this.disabledAdd    = true;
      this.disabledEdit    = true;
      this.disabledUpdate = true;
      this.disabled       = false;
      this.disabledCancel = false;
      this.canDelte       = true;

    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledEdit    = false;
      this.disabledUpdate = false;
      this.disabledCancel = true;
      this.canDelte       = false;
      if(this.lastSelected != null){
        this.selectedOrders(this.lastSelected)

      }
    },
     selectedOrders : function(rowselected){
      this.rolForm.uuid = rowselected[0].uuid;
      this.rolForm.name = rowselected[0].name;
      this.lastSelected = rowselected;
      this.disabledAdd  = false;
      this.disabledEdit = false;
    },
    clearForms(){
      this.rolForm.uuid = "";
      this.rolForm.name = "";
    },
    handleLoadPermissionDataToFormComponent(selectedPermission){
      if(selectedPermission){
        this.editPermission(selectedPermission.uuid);
        
      }
    },  

    updateTabsUi(){
      this.generalInfosSetUp = Boolean(this.currentSelectedPermission.general_infos_setup);
      this.scopeSetUp = Boolean(this.currentSelectedPermission.scope_setup);
      this.financeInfosSetUp = Boolean(this.currentSelectedPermission.financial_infos_setup);
      this.priceListInfosSetUp = Boolean(this.currentSelectedPermission.pricelist_infos_setup);
      this.synopticsInfosSetUp = Boolean(this.currentSelectedPermission.synptics_infos_setup);
    },

    handleGeneralInfoStepSavedEvent(permission){

      this.currentSelectedPermission = permission;

      this.generalInfosSetUp = true;
      // refresh contracts list
      this.shouldRefreshPermissionTable = true;
      // jump to next tab
      this.formStep = 1;
    },
    resetAll(){
      this.clearForms();
      this.disabled = true;
      this.disabledAdd = false;
      this.disabledEdit = false;
      this.disabledUpdate = false;
      this.disabledCancel = true;
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let loader = this.$loading.show({
              color: '#000000',
              loader: 'spinner',
              width: 64,
              height: 64,
              backgroundColor: '#ffffff',
              opacity: 0.5,
              zIndex: 999,
          })
          this.submitted = false
          this.$http
          .post("/system/roles/store", this.rolForm)
          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.reloadTable = true;
                this.resetAll();
                break;

              case 500:
                  this.$toast.warning(res.data.original.msg);
                break;
            }
              loader.hide();
          })
          .catch((error) => {
              this.$toast.error(error.message);
          })
          .finally(() => {});
        }
    },

    deleteRole(){
      var typeUid  = this.rolForm.uuid
      var typeName = this.rolForm.name
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le role  : " + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/system/roles/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.reloadTable = true;
                  this.resetAll();
                  this.lastSelected   = null;

                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

  }
  
};
</script>
<style>
  @media screen and (-webkit-min-device-pixel-ratio:0) {

    /*Chrome CSS here*/
    #StickyEF {
    position: fixed;
    right: 0;
    top: 50%;
    width: 4em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
    
}

@supports (-moz-appearance:none){ 
  #StickyEF {
    position: fixed;
    right: 0;
    top: 50%;
    width: 1em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
}
  .rounded-pillEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #097EA9 ;
  }
  .btn-editEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteEF #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-cancelEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
  .form-check-input{
    font-size: 24px;
  }
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des Rôles</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
               <b-card-body class="shadow-lg">
              <roles-table :reloadTable="reloadTable" @reloadTableDone="reloadTable = $event" @selectedOrders="selectedOrders"/>
               </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      </div>
      <div class="row">
      <div class="col-12">
        <div class="card">
        <div class="card-body">
          <ul id="StickyEF" >
            <li>
              <button type="button" @click="addBtn" :disabled="disabledAdd" class="btn btn-addEF rounded-pillEF float-right mr-1 mt-1">
              <i class="fas fa-plus text-white"></i>
              </button>
            </li>
            <li>
              <button type="button" @click="editBtn" :disabled="disabledEdit" class="btn btn-editEF rounded-pillEF float-right mr-1 mt-1">
                <i class="fas fa-pencil-alt text-white" style=""></i>
              </button>
            </li>
            <li>
              <button type="button" :disabled="disabledEdit" @click="deleteRole" class="btn btn-deleteEF rounded-pillEF float-right mr-1 mt-1">
                <i class="fas fa-trash-alt text-white" style=""></i>
              </button>
            </li>
          </ul>
          <h4 class="card-title col-12">
            Details 
          </h4>
          <fieldset class="col-12" :disabled="disabled">
            <form class="needs-validation" @submit.prevent="formSubmit" enctype="multipart/form-data">
              <div  icon="fas fa-file-contract" :selected="true" >
              <div class="row">
                <div class="col-xs-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label for="formrow-inputCity">Name *</label>
                    <input type="text"  class="form-control" v-model="rolForm.name" 
                      @blur="$v.rolForm.name.$error"
                      :class="{ 'is-invalid': submitted && $v.rolForm.name.$error,}" placeholder=""/>
                      <div v-if="submitted && $v.rolForm.name.$error" class="invalid-feedback">
                        <span v-if="!$v.rolForm.name.required">Le champ est obligatoire.</span>
                      </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-6 col-lg-6 mt-4" v-show="!disabled"> 
                  <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                    <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                  </button>
                  
                  <button type="submit" class="btn btn-label btn-primary float-right" >
                    <i class="far fa-save label-icon "></i> Enregistrer
                  </button>
                  
                </div>
              </div>
               </div>
            </form>
          </fieldset>
         </div>
        </div>
      </div>
    </div>
  </Layout>
</template>