var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "accordion mb-2", attrs: { role: "tablist" } },
            [
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" },
                          on: {
                            click: function($event) {
                              _vm.active = !_vm.active
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Liste des Rôles")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        visible: _vm.active,
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        { staticClass: "shadow-lg" },
                        [
                          _c("roles-table", {
                            attrs: { reloadTable: _vm.reloadTable },
                            on: {
                              reloadTableDone: function($event) {
                                _vm.reloadTable = $event
                              },
                              selectedOrders: _vm.selectedOrders
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("ul", { attrs: { id: "StickyEF" } }, [
                _c("li", [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-addEF rounded-pillEF float-right mr-1 mt-1",
                      attrs: { type: "button", disabled: _vm.disabledAdd },
                      on: { click: _vm.addBtn }
                    },
                    [_c("i", { staticClass: "fas fa-plus text-white" })]
                  )
                ]),
                _c("li", [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-editEF rounded-pillEF float-right mr-1 mt-1",
                      attrs: { type: "button", disabled: _vm.disabledEdit },
                      on: { click: _vm.editBtn }
                    },
                    [_c("i", { staticClass: "fas fa-pencil-alt text-white" })]
                  )
                ]),
                _c("li", [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-deleteEF rounded-pillEF float-right mr-1 mt-1",
                      attrs: { type: "button", disabled: _vm.disabledEdit },
                      on: { click: _vm.deleteRole }
                    },
                    [_c("i", { staticClass: "fas fa-trash-alt text-white" })]
                  )
                ])
              ]),
              _c("h4", { staticClass: "card-title col-12" }, [
                _vm._v(" Details ")
              ]),
              _c(
                "fieldset",
                { staticClass: "col-12", attrs: { disabled: _vm.disabled } },
                [
                  _c(
                    "form",
                    {
                      staticClass: "needs-validation",
                      attrs: { enctype: "multipart/form-data" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.formSubmit($event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: {
                            icon: "fas fa-file-contract",
                            selected: true
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-xs-12 col-md-6 col-lg-6" },
                              [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "label",
                                    { attrs: { for: "formrow-inputCity" } },
                                    [_vm._v("Name *")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.rolForm.name,
                                        expression: "rolForm.name"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      "is-invalid":
                                        _vm.submitted &&
                                        _vm.$v.rolForm.name.$error
                                    },
                                    attrs: { type: "text", placeholder: "" },
                                    domProps: { value: _vm.rolForm.name },
                                    on: {
                                      blur: _vm.$v.rolForm.name.$error,
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.rolForm,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm.submitted && _vm.$v.rolForm.name.$error
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          !_vm.$v.rolForm.name.required
                                            ? _c("span", [
                                                _vm._v(
                                                  "Le champ est obligatoire."
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.disabled,
                                    expression: "!disabled"
                                  }
                                ],
                                staticClass: "col-xs-12 col-md-6 col-lg-6 mt-4"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-light btn-label float-right ml-2",
                                    attrs: { type: "button" },
                                    on: { click: _vm.cancelBtn }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-times-circle text-danger label-icon"
                                    }),
                                    _vm._v(" Annuler ")
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-label btn-primary float-right",
                                    attrs: { type: "submit" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-save label-icon "
                                    }),
                                    _vm._v(" Enregistrer ")
                                  ]
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }