var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-responsive mb-0 shadow" },
    [
      _c("dataset", {
        attrs: { "ds-data": _vm.roles },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var ds = ref.ds
              return [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6 mb-2 mb-md-0" })
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", {}, [
                      _c(
                        "table",
                        { staticClass: "table table-hover d-md-table" },
                        [
                          _c("thead", [
                            _c(
                              "tr",
                              _vm._l(_vm.cols, function(th) {
                                return _c("th", { key: th.field }, [
                                  _vm._v(" " + _vm._s(th.name) + " ")
                                ])
                              }),
                              0
                            )
                          ]),
                          _c("dataset-item", {
                            attrs: { tag: "tbody" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var rowIndex = ref.rowIndex
                                    return [
                                      _c(
                                        "tr",
                                        {
                                          class: {
                                            "bg-soft-info":
                                              _vm.selectedIndex == rowIndex
                                          },
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function($event) {
                                              return _vm.selectRow(
                                                row,
                                                rowIndex
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name:
                                                        "settings.roles.permissions",
                                                      params: {
                                                        uid: row.uuid,
                                                        name: row.name
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(row.display_name)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "mr-3 text-primary",
                                                  attrs: {
                                                    to: {
                                                      name:
                                                        "settings.roles.permissions",
                                                      params: { uid: row.uuid }
                                                    },
                                                    "data-toggle": "tooltip",
                                                    "data-placement": "top",
                                                    title: "Permissions",
                                                    "data-original-title":
                                                      "Edit"
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bx bx-cog font-size-18"
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-md-row flex-column justify-content-between align-items-center"
                  },
                  [
                    _c("dataset-show", { attrs: { "ds-show-entries": 5 } }),
                    _c("dataset-pager")
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }